import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {I18nextProvider} from "react-i18next";
import i18next from "i18next";
import common_da from "./translations/da/common.json";
import common_en from "./translations/en/common.json";
//import 'https://kit.fontawesome.com/19a7f9441c.js';

import {Helmet} from "react-helmet";

i18next.init({
  interpolation: { escapeValue: false },
  lng: 'da',
  resources: {
      da: {
          common: common_da
      },
      en: {
          common: common_en
      },
  },
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18next}>
      <Helmet>
          <title>Fullstack web udvikler | React.js & Node.js</title>
          <meta name="description" content="Fullstack web udvikler | React.js & Node.js" />
      </Helmet>
      <App />
    </I18nextProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
