import React, {useState, useEffect} from 'react';
import { ACTIVE, DA, EN } from '../App';


const Clients = () => {

    return (
        
        <div className="hidden sm:grid grid-cols-2 sm:grid-cols-10 gap-8 opacity-20">
            <div>
                <img src="/assets/portfolio-justeat.png" alt="" />
            </div>
            <div>
                <img src="/assets/portfolio-42.png" alt="" />
            </div>
            <div>
                <img src="/assets/portfolio-refurb.png" alt="" />
            </div>
            <div>
                <img src="/assets/portfolio-egmont.png" alt="" />
            </div>
            <div>
                <img src="/assets/portfolio-phoneservice.png" alt="" />
            </div>
            <div>
                <img src="/assets/portfolio-immosurf.png" alt="" />
            </div>
            <div>
                <img src="/assets/portfolio-dao.png" alt="" />
            </div>
            <div>
                <img src="/assets/portfolio-ubberup.png" alt="" />
            </div>
            <div>
                <img src="/assets/portfolio-nfbio.png" alt="" />
            </div>
            <div>
                <img src="/assets/portfolio-capturi.png" alt="" />
            </div>
            <div>
                <img src="/assets/portfolio-givesteel.png" alt="" />
            </div>
            <div>
                <img src="/assets/portfolio-daydose.png" alt="" />
            </div>
            <div>
                <img src="/assets/portfolio-phonecare.png" alt="" />
            </div>
            <div>
                <img src="/assets/portfolio-plangy.png" alt="" />
            </div>
            <div>
                <img src="/assets/portfolio-visma.png" alt="" />
            </div>
            <div>
                <img src="/assets/portfolio-webshipper.png" alt="" />
            </div>
            <div>
                <img src="/assets/portfolio-deloitte.png" alt="" />
            </div>
            <div>
                <img src="/assets/portfolio-ball.png" alt="" />
            </div>
            <div>
                <img src="/assets/portfolio-alkalaer.png" alt="" />
            </div>
            <div>
                <img src="/assets/portfolio-emplate.png" alt="" />
            </div>
        </div>
    )
}

export default Clients;