import React, {useState, useEffect} from 'react';
import './App.css';
import {useTranslation} from "react-i18next";
import { Button } from './components/Button';
import AnimatedText from './components/AnimatedText';
import Clients from './components/Clients';
import Slider from "./components/ClientsSlider";
import Cases from "./components/Cases";


export const DA = 'da';
export const EN = 'en';
export const ACTIVE = 'active';

const LanguageComponent = () => {

  const [t, i18n] = useTranslation('common');
  const [Lang, setLang] = useState(DA);

  const btnToggle = (lang:string) => {
    i18n.changeLanguage(lang)
    setLang(lang)
  }

  return <>
    <div className="language flex gap-6 text-sm">
      <Button 
        onClickHandler={() => btnToggle(DA)}
        btnStyle={Lang === DA ? ACTIVE : ""}
      >
        Dansk
      </Button>

      <Button 
        onClickHandler={() => btnToggle(EN)}
        btnStyle={Lang === EN ? ACTIVE : ""}
      >
        English
      </Button>
  </div>
  </>
}

function App() {

  const {t, i18n} = useTranslation('common');

  const [isScrolled, setIsScrolled] = useState(false);

  const handleScroll = () => {
    // Get the current scroll position
    const scrollY = window.scrollY;

    // Define a threshold for when to apply the class
    const scrollThreshold = 100; // Adjust as needed

    // Update the state based on the scroll position
    if (scrollY > scrollThreshold) {
    setIsScrolled(true);
    } else {
    setIsScrolled(false);
    }

  }
      
  useEffect(() => {
      // Add a scroll event listener
      window.addEventListener('scroll', handleScroll);

      return () => {
      // Remove the event listener when the component unmounts
      window.removeEventListener('scroll', handleScroll);
      };
  }, []);

  return (
    <>
    <div className="wrapper w-full overflow-hidden">
      <div className="header fixed z-50 mix-blend-difference w-full">
        <div className="content flex mx-auto py-6 px-6 sm:px-0 relative">
          <div className="logo">
            <img src="/assets/nativo.png" alt="" className="h-6" />
          </div>
          <div className="navigation ml-auto mr-0 relative">
            <ul className="flex gap-4 relative">
              <li className={`h-6 flex gap-4 items-center absolute ${isScrolled ? 'deactive' : 'active'}`}>

                <LanguageComponent />

              </li>
              <li className={`h-6 flex gap-6 items-center absolute ${isScrolled ? 'active' : 'deactive'}`}>
                <a href="mailto:jwl@nativo.dk" className="text-sm flex text-cta" rel="noreferrer" target="_blank">
                  {t('common.contact')}
                </a>

                <a href="https://www.linkedin.com/in/wington/" rel="noreferrer" target="_blank">
                  <i className="fa-brands fa-linkedin text-cta"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="main px-6 sm:px-0">
        <div className="hero h-screen content mx-auto flex items-center">
          <div className="w-full sm:w-6/12 flex py-6">

            <div className="flex flex-col">
              <AnimatedText lang={i18n.language} text={t('hero.title')} size="h1" custom="text-5xl sm:text-9xl tracking-wider sm:-ml-2.5 mb-4" />
              <AnimatedText lang={i18n.language} text={t('hero.subtitle')} size="h2" custom="text-2xl sm:text-4xl !leading-tight -ml-0.5 tracking-wider" />
            </div>

          </div>
        </div>
        <div className="section sm:mb-20 relative -top-10">
          <div className="content flex-col sm:flex-row flex gap-20 mx-auto !max-w-7xl min-h-20 sm:px-16 py-20 rounded-2xl">
            <div className="w-full sm:w-6/12">
              <h2 className="text-xl">{t('frontend.title')}</h2>
              <h3 className="text-base text-cta mt-2 mb-10">React.js <i className="fa-solid fa-slash-forward"></i> Next.js</h3>
              <p className="text-base leading-relaxed mb-6">{t('frontend.text1')}</p>
              <p className="text-base leading-relaxed">{t('frontend.text2')}</p>
            </div>
            <div className="w-full sm:w-6/12">
              <h2 className="text-xl">{t('backend.title')}</h2>
              <h3 className="text-base text-cta mt-2 mb-10">Node.js <i className="fa-solid fa-slash-forward"></i> PostgreSQL</h3>
              <p className="text-base leading-relaxed mb-6">{t('backend.text1')}</p>
              <p className="text-base leading-relaxed">{t('backend.text2')}</p>
            </div>
          </div>
        </div>
        <div className="section sm:mb-36 relative">
          <div className="content flex flex-col gap-20 mx-auto min-h-20 py-20 rounded-2xl">
            <h4 className="text-2xl">{t('clients.title')} <span className="text-cta text-xs">{t('clients.subtitle')}</span></h4>
            <Clients />
            <Slider />
          </div>

          <Cases />

        </div>
        <div className="section mb-20 sm:mb-44 relative">
          <div className="content flex flex-col gap-20 mx-auto min-h-20 py-20 rounded-2xl">
            <h4 className="text-2xl">{t('process.title')} </h4>
            <div className="grid grid-cols-1 sm:grid-cols-5 gap-8">
              <div>
                <h5 className="mb-4 flex items-center gap-2">{t('process.step1-title')} 
                  <span className="text-cta text-xs">
                    <i className="fa-solid fa-slash-forward mr-1"></i>
                    {t('process.step1-subtitle')} 
                  </span>
                </h5>
                <p className="text-sm leading-relaxed">{t('process.step1-text')} </p>
              </div>
              <div>
                <h5 className="mb-4 flex items-center gap-2">{t('process.step2-title')}  
                  <span className="text-cta text-xs">
                    <i className="fa-solid fa-slash-forward mr-1"></i>
                    {t('process.step2-subtitle')} 
                  </span>
                </h5>
                <p className="text-sm leading-relaxed">{t('process.step2-text')} </p>
              </div>
              <div>
                <h5 className="mb-4 flex items-center gap-2">{t('process.step3-title')}  
                  <span className="text-cta text-xs">
                    <i className="fa-solid fa-slash-forward mr-1"></i>
                    {t('process.step3-subtitle')} 
                  </span>
                </h5>
                <p className="text-sm leading-relaxed">{t('process.step3-text')} </p>
              </div>
              <div>
                <h5 className="mb-4 flex items-center gap-2">{t('process.step4-title')}  
                  <span className="text-cta text-xs">
                    <i className="fa-solid fa-slash-forward mr-1"></i>
                    {t('process.step4-subtitle')} 
                  </span>
                </h5>
                <p className="text-sm leading-relaxed">{t('process.step4-text')} </p>
              </div>
              <div>
                <h5 className="mb-4 flex items-center gap-2">{t('process.step5-title')}  
                  <span className="text-cta text-xs">
                    <i className="fa-solid fa-slash-forward mr-1"></i>
                    {t('process.step5-subtitle')} 
                  </span>
                </h5>
                <p className="text-sm leading-relaxed">{t('process.step5-text')} </p>
              </div>
            </div>
          </div>
        </div>
        <div className="section mb-44 relative">
          <div className="content flex flex-col gap-20 mx-auto min-h-20 py-20 px-6 sm:px-20 rounded-2xl !max-w-7xl bg-cta">
              <AnimatedText lang={i18n.language} text={t('contact.title')} size="h1" custom="text-2xl sm:text-4xl tracking-wider mb-4" />
              <div className="flex flex-col gap-4 sm:gap-2">
                <a href="mailto:jwl@nativo.dk" className="text-base mr-auto sm:text-lg">{t('contact.mailme')} <i className="fa-light fa-arrow-right-long"></i></a>
                <a href="https://www.linkedin.com/in/wington/" className="text-base mr-auto sm:text-lg" target="_blank" rel="noreferrer">{t('contact.linkedinme')} <i className="fa-light fa-arrow-right-long"></i></a>
              </div>
              <div>
                <span>{t('contact.text')}</span>
              </div>
          </div>
        </div>
      </div>
      <div className="footer text-center mb-6">
        <span className="opacity-30 text-xs">{t('copyright.text')}</span>
      </div>
    </div>
    </>
  );
}

export default App;
