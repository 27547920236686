import React, { useState, useEffect, useRef } from 'react';
import { useSpring, animated } from 'react-spring';
import {Trans, useTranslation} from "react-i18next";
import { ACTIVE, DA, EN } from '../App';

const AnimatedText: React.FC<{ lang:string, text: string, size: string, custom: string }> = ({ lang, text, size, custom }) => {

    const {t, i18n} = useTranslation('common');

    const [displayText, setDisplayText] = useState('');
    const [textHeight, setTextHeight] = useState<number | null>(null);
  
    const containerRef = useRef<HTMLDivElement>(null);
    const textRef = useRef<HTMLHeadingElement | null>(null);
    const observer = useRef<IntersectionObserver | null>(null);
    const animationInterval = useRef<NodeJS.Timeout | null>(null);
    const currentIndex = useRef(0);

    const textFinal = (text:string) => {
        
        const textHtml = text.split(';').join('<br/>');
        return textHtml;

    }

    const [textRender, setTextRender] = useState(() => textFinal(text)); 

    const textAnimation = (letter:string) => {

        if(letter === ';'){
            letter = '<br/>';
        }
        if(letter === '_'){
            letter = '<i class="text-cta">_</i>';
        }

        setDisplayText((prevText) => prevText + letter);

    }
  
    const startAnimation = (text:string) => {

        if (textHeight === null) {
            return; // Text height not yet calculated
        }

        if (animationInterval.current) {
            return; // Animation already running
        }

        currentIndex.current = 0;
        setDisplayText('');

        animationInterval.current = setInterval(() => {

            if (currentIndex.current < text.length) {
                textAnimation(text.charAt(currentIndex.current));
                currentIndex.current++;
                console.log('hey1');
            } else {
                clearInterval(animationInterval.current!);
                animationInterval.current = null;
                console.log('hey2');
            }

        }, 30);
    };
  
    useEffect(() => {

        setTextRender(textFinal(text));

        if (containerRef.current && !animationInterval.current) {
          const observerCallback: IntersectionObserverCallback = (entries) => {
            entries.forEach((entry) => {
              if (entry.isIntersecting) {
                startAnimation(text);
              }
            });
          };
    
          observer.current = new IntersectionObserver(observerCallback, { rootMargin: '0px' });
          observer.current.observe(containerRef.current);
        }
    
        return () => {
            if (animationInterval.current) {
                clearInterval(animationInterval.current);
            }
            if (observer.current) {
                observer.current.disconnect();
            }
        };
    }, [lang, text, textHeight]);

    useEffect(() => {
        if (textRef.current) {
          const height = textRef.current.getBoundingClientRect().height;
          setTextHeight(height);
        }
        if (observer.current && observer.current.root && observer.current.root.contains(containerRef.current)) {
          startAnimation(text);
        }
    }, [lang, text]);

    return (
        <div className={`w-full flex ${custom}`} ref={containerRef} style={{ height: textHeight || 'auto' }}>
            <animated.div className="text-outer uppercase">
                {size === 'h1' && displayText === '' && <h1 className="opacity-0" ref={textRef} dangerouslySetInnerHTML={{ __html: textRender }} />}
                {size === 'h2' && displayText === '' && <h2 className="opacity-0" ref={textRef} dangerouslySetInnerHTML={{ __html: textRender }} />}
                {size === 'h3' && displayText === '' && <h3 className="opacity-0" ref={textRef} dangerouslySetInnerHTML={{ __html: textRender }} />}

                {size === 'h1' && <h1 dangerouslySetInnerHTML={{ __html: displayText }} />}
                {size === 'h2' && <h2 dangerouslySetInnerHTML={{ __html: displayText }} />}
                {size === 'h3' && <h3 dangerouslySetInnerHTML={{ __html: displayText }} />}
            </animated.div>
        </div>
    );
};

export default AnimatedText;