import React, {useState, useEffect} from 'react';
import { ACTIVE, DA, EN } from '../App';


export const Button = ({children, onClickHandler, btnStyle}:any) => {

    return (
        <button onClick={onClickHandler} className={`${btnStyle}`}>
            {children}
        </button>
    )
}