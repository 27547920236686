import React, {useState, useEffect} from 'react';
import { ACTIVE, DA, EN } from '../App';
import {Trans, useTranslation} from "react-i18next";
import Slider from "react-slick";

const Cases = () => {

    const {t, i18n} = useTranslation('common');
    const [Lang, setLang] = useState(DA);

    const [blobColor1, setBlobColor1] = useState("#00d2ff");
    const [blobColor2, setBlobColor2] = useState("#0096ff");
    const [blobColor3, setBlobColor3] = useState("#394cc6");
  
    const CustomPrevArrow = ({ onClick, className }:any) => (
      <button className={`slider-nav prev ${className}`} onClick={onClick}>
        <i className="fa-light fa-arrow-left"></i>
      </button>
    );
    const CustomNextArrow = ({ onClick, className }:any) => (
      <button className={`slider-nav next ${className}`} onClick={onClick}>
        <i className="fa-light fa-arrow-right"></i>
      </button>
    );

    const onSlideChange = (currentSlide:any) => {
        console.log(currentSlide)
        if(currentSlide === 0){
            setBlobColor1("#00d2ff")
            setBlobColor2("#0096ff")
            setBlobColor3("#394cc6")
        }else if(currentSlide === 1){
            setBlobColor1("#8fdfc2")
            setBlobColor2("#00ffa3")
            setBlobColor3("#65edbc")
        }else if(currentSlide === 2){
            setBlobColor1("#cd7dff")
            setBlobColor2("#e14bf0")
            setBlobColor3("#7c40ca")
        }else if(currentSlide === 3){
            setBlobColor1("#f9e8d0")
            setBlobColor2("#1206b4")
            setBlobColor3("#1200ff")
        }else if(currentSlide === 4){
            setBlobColor1("#a1bdd8")
            setBlobColor2("#35587a")
            setBlobColor3("#0860b5")
        }else if(currentSlide === 5){
            setBlobColor1("#bac6ba")
            setBlobColor2("#415941")
            setBlobColor3("#4d8b4d")
        }
    }

    var settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      prevArrow: <CustomPrevArrow />,
      nextArrow: <CustomNextArrow />,
      afterChange: onSlideChange
    };

    return (
        <div className="cases-outer">
            <div className="cases max-w-7xl rounded-xl mx-auto bg-black overflow-hidden relative z-20">
                <Slider {...settings}>
                    <div>
                        <div className="w-full flex flex-col sm:flex-row">
                            <div className="w-full sm:w-6/12">
                                <img src="/img/nolme-screen.png" alt="" className="w-full sm:h-full" />
                            </div>
                            <div className="w-full sm:w-6/12 p-10 flex flex-col">
                                <span className="text-3xl">Nolmë</span>
                                <span className={`mb-8 transition duration-100`} style={{color: "#394cc6"}}>{t('cases.nol-title')}</span>
                                <p className="mb-4">{t('cases.nol-text1')}</p>
                                <p className="mb-4">{t('cases.nol-text2')}</p>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="w-full flex flex-col sm:flex-row">
                            <div className="w-full sm:w-6/12">
                                <img src="/img/plangy-screen.png" alt="" className="w-full h-full" />
                            </div>
                            <div className="w-full sm:w-6/12 p-10 flex flex-col">
                                <span className="text-3xl">Plangy</span>
                                <span className={`mb-8 transition duration-100`} style={{color: "#6fd9b3"}}>{t('cases.plgy-title')}</span>
                                <p className="mb-4">{t('cases.plgy-text1')}</p>
                                <p>{t('cases.plgy-text2')}</p>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="w-full flex flex-col sm:flex-row">
                            <div className="w-full sm:w-6/12">
                                <img src="/img/cosmodinos-screen.png" alt="" className="w-full h-full" />
                            </div>
                            <div className="w-full sm:w-6/12 p-10 flex flex-col">
                                <span className="text-3xl">Cosmodinos</span>
                                <span className={`mb-8 transition duration-100`} style={{color: "#8a4bcb"}}>{t('cases.cos-title')}</span>
                                <p className="mb-4">{t('cases.cos-text1')}</p>
                                <p>{t('cases.cos-text2')}</p>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="w-full flex flex-col sm:flex-row">
                            <div className="w-full sm:w-6/12">
                                <img src="/img/webshipper-screen.png" alt="" className="w-full h-full" />
                            </div>
                            <div className="w-full sm:w-6/12 p-10 flex flex-col">
                                <span className="text-3xl">Webshipper</span>
                                <span className={`mb-8 transition duration-100`} style={{color: "#1206b4"}}>{t('cases.ws-title')}</span>
                                <p className="mb-4">{t('cases.ws-text1')}</p>
                                <p>{t('cases.ws-text2')}</p>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="w-full flex flex-col sm:flex-row">
                            <div className="w-full sm:w-6/12">
                                <img src="/img/balloriginal-screen.png" alt="" className="w-full h-full" />
                            </div>
                            <div className="w-full sm:w-6/12 p-10 flex flex-col">
                                <span className="text-3xl">Ball Original</span>
                                <span className={`mb-8 transition duration-100`} style={{color: "#35587a"}}>{t('cases.bo-title')}</span>
                                <p className="mb-4">{t('cases.bo-text1')}</p>
                                <p>{t('cases.bo-text2')}</p>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="w-full flex flex-col sm:flex-row">
                            <div className="w-full sm:w-6/12">
                                <img src="/img/plast1-screen.png" alt="" className="w-full h-full" />
                            </div>
                            <div className="w-full sm:w-6/12 p-10 flex flex-col">
                                <span className="text-3xl">Plast1</span>
                                <span className={`mb-8 transition duration-100`} style={{color: "#415942"}}>{t('cases.p1-title')}</span>
                                <p className="mb-4">{t('cases.p1-text1')}</p>
                                <p>{t('cases.p1-text2')}</p>
                            </div>
                        </div>
                    </div>
                </Slider>
            </div>

            <div>
                <div className="absolute -bottom-60 right-60 z-0 opacity-20">
                    <svg width="1200" height="1000" viewBox="0 0 355 200" preserveAspectRatio="xMidYMid slice" fill="none" xmlns="http://www.w3.org/2000/svg" className="w-auto h-full artwork">
                    <rect width="355" height="200"></rect> 
                    <circle id="blob-0" cx="177.5" cy="100" r="50" fill={blobColor1} filter="url(#f0)"></circle> 
                    <defs> 
                        <filter id="f0" x="-25%" y="-25%" width="150%" height="150%" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                        <feGaussianBlur stdDeviation="24" result="fx_foregroundBlur"></feGaussianBlur>   
                        </filter>
                    </defs> 
                    </svg>
                </div>

                <div className="absolute -bottom-72 -right-10 z-10 opacity-30">
                    <svg width="1200" height="1000" viewBox="0 0 355 200" preserveAspectRatio="xMidYMid slice" fill="none" xmlns="http://www.w3.org/2000/svg" className="w-auto h-full artwork">
                    <rect width="355" height="200"></rect> 
                    <circle id="blob-0" cx="177.5" cy="100" r="50" fill={blobColor2} filter="url(#f0)"></circle> 
                    <defs> 
                        <filter id="f0" x="-25%" y="-25%" width="150%" height="150%" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                        <feGaussianBlur stdDeviation="24" result="fx_foregroundBlur"></feGaussianBlur>   
                        </filter>
                    </defs> 
                    </svg>
                </div>

                <div className="absolute -bottom-60 -right-60 z-10 opacity-50">
                    <svg width="1000" height="800" viewBox="0 0 355 200" preserveAspectRatio="xMidYMid slice" fill="none" xmlns="http://www.w3.org/2000/svg" className="w-auto h-full artwork">
                    <rect width="355" height="200"></rect> 
                    <circle id="blob-0" cx="177.5" cy="100" r="50" fill={blobColor3} filter="url(#f0)"></circle> 
                    <defs> 
                        <filter id="f0" x="-25%" y="-25%" width="150%" height="150%" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                        <feGaussianBlur stdDeviation="24" result="fx_foregroundBlur"></feGaussianBlur>   
                        </filter>
                    </defs> 
                    </svg>
                </div>
            </div>

        </div>
    )
}

export default Cases;