import React, {useState, useEffect} from 'react';
import { ACTIVE, DA, EN } from '../App';
import Slider from "react-slick";

const ClientsSlider = () => {

    var settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 2,
      slidesToScroll: 2
    };

    return (
        
        <div className="overflow-hidden sm:hidden">
            <Slider {...settings}>
                <div>
                    <img src="/assets/portfolio-dao.png" alt="" />
                </div>
                <div>
                    <img src="/assets/portfolio-capturi.png" alt="" />
                </div>
                <div>
                    <img src="/assets/portfolio-justeat.png" alt="" />
                </div>
                <div>
                    <img src="/assets/portfolio-42.png" alt="" />
                </div>
                <div>
                    <img src="/assets/portfolio-visma.png" alt="" />
                </div>
                <div>
                    <img src="/assets/portfolio-egmont.png" alt="" />
                </div>
                <div>
                    <img src="/assets/portfolio-refurb.png" alt="" />
                </div>
                <div>
                    <img src="/assets/portfolio-ubberup.png" alt="" />
                </div>
                <div>
                    <img src="/assets/portfolio-nfbio.png" alt="" />
                </div>
                <div>
                    <img src="/assets/portfolio-webshipper.png" alt="" />
                </div>
                <div>
                    <img src="/assets/portfolio-givesteel.png" alt="" />
                </div>
                <div>
                    <img src="/assets/portfolio-plangy.png" alt="" />
                </div>
                <div>
                    <img src="/assets/portfolio-deloitte.png" alt="" />
                </div>
                <div>
                    <img src="/assets/portfolio-ball.png" alt="" />
                </div>
                <div>
                    <img src="/assets/portfolio-alkalaer.png" alt="" />
                </div>
                <div>
                    <img src="/assets/portfolio-emplate.png" alt="" />
                </div>
            </Slider>
        </div>
    )
}

export default ClientsSlider;